import FontIcon from 'components/icons';

import './styles/bubble-list.scss';

type BubbleListProps = {
  bubble_items: BubbleItem[];
  desktop_image: {
    source_url: string;
  },
  mobile_image: {
    source_url: string;
  };
  subtitle: string;
  title: string;
}

type BubbleItem = {
  icon: string;
  text: string;
}

export const BubbleList = ({
  title,
  subtitle,
  desktop_image,
  mobile_image,
  bubble_items,
}: BubbleListProps) => {
  return (
    <div className="bubble-list-section">
      <div className="container">
        <div className="content">
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
          <div className="bubble-items-list">
            {bubble_items.map((item, index) => {
              return (
                <div key={ index } className="bubble-item">
                  <FontIcon icon= { bubble_items[index].icon } className="icon-user" />
                  {bubble_items[index].text}
                </div>
              );
            })}
          </div>
        </div>
        <div className="side-image">
          <picture className="image-container">
            <source
              srcSet={ desktop_image.source_url }
              media="(min-width: 769px)"
            />
            <img src={ mobile_image.source_url } alt="Laptop free scheduling picture" />
          </picture>
        </div>
      </div>
    </div>
  );
};